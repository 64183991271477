// import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
// import { catchError, throwError } from 'rxjs';
// import { CommonService } from '../services/common.service';
// import { inject } from '@angular/core';

// export const AuthInterceptor: HttpInterceptorFn = (req, next) => {

//   const service = inject(CommonService);

//   return next(req).pipe(
//     catchError((error) => {
//       if (error instanceof HttpErrorResponse) {
//         if (error.status === 401) {
//           // console.log(error);
//           service.snackBarMessage(
//             `${error.error.message}`,
//             'Close'
//           )
//         } else {
//           const errorMessage = error.error.detail || error.error.details;
//           if (errorMessage) {
//             // console.log(errorMessage);
//             service.snackBarMessage(
//               `${error.error.message}`,
//               'Close'
//             )
//             // service.snackBarMessage(errorMessage, 'Close');
//           } else {
//             // console.log('An unknown error occurred');
//             service.snackBarMessage(
//               `${error.error.message}`,
//               'Close'
//             )
//             // service.snackBarMessage('An unknown error occurred', 'Close');
//           }
//         }
//       } else {
//         const errorMessage = error.error.detail || error.error.details;
//         if (errorMessage) {
//           // console.log(errorMessage);
//           service.snackBarMessage(
//             `${error.error.message}`,
//             'Close'
//           )
//           // service.snackBarMessage(errorMessage, 'Close');
//         } else {
//           // console.log('An unknown error occurred');
//           service.snackBarMessage(
//             `${error.error.message}`,
//             'Close'
//           )
//           // service.snackBarMessage('An unknown error occurred', 'Close');
//         }
//       }
//       return throwError(() => error);
//     })
//   );
// };


import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from "rxjs";
import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { CommonService } from "../services/common.service";


const TOKEN_HEADER_KEY = 'Authorization';

export const AuthInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
  let token :any ;
  const authService = inject(AuthService);
  const refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  let isRefreshing = false;
  const service = inject(CommonService);

  if (req.url.includes('registration/token')) {
    token = localStorage.getItem('refToken');
  } else {
    token = localStorage.getItem('token');
  }

  // Clone the request and set the Authorization header
  let authReq = req
  if(token){
    authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  return next(authReq).pipe(
    catchError((error)=>{
      if(error instanceof HttpErrorResponse && error.status === 401){
        if(!isRefreshing){
          isRefreshing = true;
          refreshTokenSubject.next(null);
          return authService.refreshToken().pipe(
            switchMap((tokenReceived:any) => {
              isRefreshing = false;
              // authService.storeToken(tokenReceived.data.token)
              localStorage.setItem('token',tokenReceived.data.token)
              // authService.storeRefToken(tokenReceived.data.refreshToken);
              localStorage.setItem('refToken',tokenReceived.data.refreshToken);
              refreshTokenSubject.next(tokenReceived.data.token);
              if (token) {
                req = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${tokenReceived.data.token}`
                  }
                });
              }
              return next(req);
            }
          ));
        }
        return refreshTokenSubject.pipe(
          filter(token => token !== null),
          take(1),
          switchMap((token) => next(req)
        ))
      }
      else{
        service.snackBarMessage(
          `${error.error.message}`,
          'Close'
        )
      }
      return throwError(() => error); 
    })
  )
};
