import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

export const routes: Routes = [
    {
        path:'',
        redirectTo:'',
        pathMatch:'full'
    },
    {
        path:'',
        loadChildren:()=>import('./layouts/auth/auth.module').then(m=>m.AuthModule)
    },
    {
        path:'admin',
        loadChildren:()=>import('./layouts/common-admin-layout/common-admin-layout.module').then(m=>m.CommonAdminLayoutModule)
    }
];
