import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { CommonService } from './services/common.service';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { FaviconService } from './services/favicon.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'dashboard';

  constructor(
    private commonService: CommonService,
    private titleService: Title,
    private favService : FaviconService
  ) {
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getThemeColors();
  }

  data: any = {
    url: "",
    erp: "",
  }
  themeData: any;
  getThemeColors() {
    if(window.location.origin.includes('localhost')){
      this.data.url = 'https://jewellers.stagessp.finshakti.com/';
    }
    else{
      this.data.url = window.location.href;
    }
    this.data.erp = true;
    this.commonService.getThemes(this.data).subscribe((res: any) => {
      this.titleService.setTitle(res.data.name+'Dashboard');
      this.favService.updateFavicon(res.data.logo);
      this.themeData = res.data;
      environment.primaryColor = this.themeData.primaryColor;
      environment.logoImage = this.themeData.logo;
      environment.companyName = this.themeData.name;
      environment.bannerImage = this.themeData.bannerImage;
      environment.bId = this.themeData.id;
    })
  }
}
